<template>
  <LayoutFilter :onFilter="ok" :onReset="resetForm" :toggleCollapseEnable="false">
    <el-form :model="filterForm" :rules="rules" class="filter-from" label-width="70px" ref="commentFilterForm"
      size="medium">

      <div class="filter-item">
        <el-form-item label="评论内容：" prop="keyword">
          <el-input @keyup.enter.native="ok" placeholder="请输入内容" v-model="form.keyword"></el-input>
        </el-form-item>

      </div>
      <div class="filter-item">
        <DatePeriodPicker :end-time.sync="form.end_time" :start-time.sync="form.start_time" end-prop="end_time"
          label="时间：" start-prop="start_time" />
      </div>
    </el-form>
  </LayoutFilter>
</template>

<script>
  import LayoutFilter from "@/base/layout/LayoutFilter";
  import DatePeriodPicker from "@/base/components/Form/DatePeriodPicker";

  export default {
  name: "ListFilter",
  components: { LayoutFilter, DatePeriodPicker },
  props: {
    filterForm: {
      type: Object,
    },
  },
  data() {
    return {
      rules: {},
      form: null,
    };
  },
  watch: {
    filterForm: {
      deep: true,
      handler(val) {
        this.form = val;
      },
    },
  },
  created() {
    this.form = this.filterForm;
  },
  methods: {
    //重置表单
    resetForm() {
      this.$refs["commentFilterForm"].resetFields();
      this.ok()
    },
    //筛选
    ok() {
      this.$emit("update", this.form);
    },
  },
};
</script>

<style scoped>
</style>
