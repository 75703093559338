<template>
  <el-dialog :visible="value" @close="close" class="detail-dialog dialog-vertical" close-on-click-modal close-on-press-escape
             title="评论详情" width="500px">
    <el-form @submit.native.prevent label-width="90px" size="medium" v-loading="loading">
      <el-form-item label="评论状态：">
        <list-status-text :text="detail.check_text" v-if="detail && detail.check_text" />
      </el-form-item>
      <el-form-item label="所属听书：">
        <!--
         "contentText": {
        "id": "8PXbMm2W",
        "name": "嘉话丑说—洞察商业的底层逻辑"
        },
        -->
        {{(detail.contentText ? detail.contentText.name : '') | placeholder }}
      </el-form-item>
      <el-form-item label="评论内容：">{{detail.content}}</el-form-item>
<!--      <el-form-item label="回复数：">{{detail.replies_count}}</el-form-item>-->
<!--      <el-form-item label="用户点赞：">{{detail.likes_count}}</el-form-item>-->
<!--
      <el-form-item label="举报数：">
        {{detail.complaints_count}}
        <span class="view-details" @mouseover="enter()" @mouseout="leave()"> >>>查看详情<<< </span>
        <transition name="slide-fade">
          <div class="reports-show" v-if="isShow">
            <ul>
              <li>淫秽色情 {{report.porn}}</li>
              <li>政治反动 {{report.reactionary}}</li>
              <li>营销广告 {{report.advertising}}</li>
              <li>恶意攻击谩骂 {{report.Dirty_words}}</li>
              <li>违法有害信息 {{report.harmful}}</li>
            </ul>
          </div>
        </transition>
      </el-form-item>
      -->
      <el-form-item label="评论时间：">{{detail.create_time}}</el-form-item>
      <el-form-item label="评论者：">{{detail.nickname}}</el-form-item>
<!--      <el-form-item label="评论IP：">{{detail.user_ip}}</el-form-item>-->
      <el-divider v-if="detail.ancestor"></el-divider>
      <div v-if="detail.ancestor">
        <p class="parent-comment">上级评论</p>
        <el-form-item label="评论内容：">{{detail.ancestor.content}}</el-form-item>
        <el-form-item label="评论时间：">{{detail.ancestor.create_time}}</el-form-item>
        <el-form-item label="评论者：">{{detail.ancestor.nickname}}</el-form-item>
<!--        <el-form-item label="评论IP：">{{detail.ancestor.user_ip}}</el-form-item>-->
      </div>
    </el-form>
    <template #footer>
      <el-button @click="$emit('uncheck', id);close()" size="small" type="danger">不通过</el-button>
      <el-button @click="$emit('check', id);close()" size="small" type="primary">通过</el-button>
    </template>
  </el-dialog>
</template>

<script>
  import {getCommentDetails,} from "../../api/comment-list";
  import ListStatusText from '../../../../base/components/List/ListStatusText'

  export default {
    name: "DetailDialog",
    components: {ListStatusText},
    props: {
      value: {
        type: Boolean,
      },
      id: {
        type: [Number, String],
      },
    },
    data() {
      return {
        loading: false,
        detail: {},
        report: {},
        isShow: false,
        reportDialog: {
          show: false,
        },
      };
    },
    watch: {
      value(val) {
        if (val) {
          this.getDetail();
        }
      },
    },
    methods: {
      saveVirtual() {

      },
      enter() {
        this.isShow = true;
      },
      leave() {
        this.isShow = false;
      },
      close() {
        this.$emit("input", false);
      },
      getDetail() {
        this.loading = true;
        getCommentDetails({
          id: this.id,
        })
          .then((res) => {
            this.detail = res.data;
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
          });

      },
      toReportDetail() {
        this.reportDialog.show = true;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .el-form .el-link {
    line-height: 1.5;
  }

  .reports-show {
    position: absolute;
    top: 10px;
    left: 110px;
    width: 280px;
    height: 70px;
    padding: 5px 10px;
    background: rgba(255, 249, 188, 1);
    border-radius: 4px;
    border: 1px solid rgba(239, 229, 126, 1);
    font-size: 14px;
    color: rgba(139, 87, 42, 1);
  }

  ul,
  li {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  .reports-show ul li {
    float: left;
    margin-right: 15px;
  }

  .view-details {
    margin-left: 10px;
    font-size: 16px;
    color: rgba(70, 128, 255, 1);
    line-height: 22px;
    cursor: pointer;
  }

  .parent-comment {
    margin-bottom: 15px;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
  }

  .el-form-item {
    margin-bottom: 0;
  }
</style>
