import api from '@/base/utils/request'

export const getListData = (data) => {
  return api({
    url: '/admin/classroom/CourseComment/index',
    method: 'post',
    data,
  })
}

export const softDelete = (data) => {
  return api({
    url: '/admin/classroom/course_comment/softDelete',
    method: 'post',
    data,
  })
}

export const putBack = (data) => {
  return api({
    url: '/admin/classroom/course_comment/putBack',
    method: 'post',
    data,
  })
}

export const trueDelete = (data) => {
  return api({
    url: '/admin/classroom/course_comment/delete',
    method: 'post',
    data,
  })
}

export const checkAudioComment = (data) => {
  return api({
    url: '/admin/classroom/course_comment/check',
    method: 'post',
    data,
    notCancel: true,
  })
}

export const uncheckAudioComment = (data) => {
  return api({
    url: '/admin/classroom/course_comment/uncheck',
    method: 'post',
    data,
    notCancel: true,
  })
}

export const getCommentDetails = (data) => {
  return api({
    url: '/admin/classroom/course_comment/detail',
    method: 'post',
    data,
  })
}
