<template>
  <el-dialog :visible="visible" @close="close()" class="dialog-vertical" title="不通过审核"
             width="500px">
    <el-form :model="form" :rules="rules" @submit.native.prevent="()=>{}" class="medium-form"
             label-width="65px"
             ref="reasonContent" size="medium">
      <el-form-item label="原因：" prop="content" style="margin-bottom: 0">
        <el-input :maxlength="20" placeholder="请输入内容" show-word-limit style="min-height: 120px;"
                  type="textarea" v-focus v-model="form.content" />
<!--        <p class="info"><i class="el-icon-info" />将通过订阅消息、系统消息发送通知</p>-->
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button @click="close()" size="small">取消</el-button>
      <el-button :loading="loading" @click="submit()" size="small" type="primary">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  export default {
    name: "CheckDialog",
    data() {
      return {
        visible: false,
        rules: {
          content: [{ required: true, message: "请输入不通过原因", trigger: "blur" }],
        },
        loading: false,
        form: {
          content: "",
          id: 0
        }
      }
    },
    methods: {
      open({id} = {id: 0}) {
        this.form.id = id
        this.visible = true
      },
      close() {
        this.visible = false
        this.$refs['reasonContent'].clearValidate()
        this.loading = false
      },
      submit() {
        this.$refs["reasonContent"].validate(valid => {
          if (valid) {
            // this.loading = true
            this.$emit('confirm', this.form)
            this.close()
          }
        })
      }
    },
  }
</script>

<style scoped>

</style>
