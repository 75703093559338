<template>
  <div class="list-page">
    <list-filter :filterForm="filterForm" @update="doFilter" />
    <list-tabs :isLoading="loading" :tabs="tabArr" @tab-click="handleTabClick" v-model="filterForm.tab">
    </list-tabs>
    <div class="list-content" v-loading="loading">
      <div class="list-btn" v-if="filterForm.tab === 'blacklist'">
<!--        <el-button type="primary" size="small" @click="toUpdateBlacklist(0, 0)">移出黑名单</el-button>-->
      </div>
      <div class="list-btn" v-if="filterForm.tab === 'deleted'">
        <el-button @click="toPutBack()" size="small" type="success">还原</el-button>
        <el-button @click="toDelete(0, true)" size="small" type="danger">删除</el-button>
      </div>
      <div class="list-btn" v-if="filterForm.tab === 'normal'">
        <el-button @click="toUpdateStatus(0, 2)" size="small" type="primary">通过</el-button>
        <el-button @click="openCheckDialog()" size="small" type="danger">不通过</el-button>
        <el-button @click="toDelete()" size="small" type="danger">删除</el-button>
      </div>
      <div class="list-btn" v-if="filterForm.tab === 'checked' || filterForm.tab === 'rejected'">
        <el-button @click="toDelete()" size="small" type="danger">删除</el-button>
      </div>
      <el-table :data="tbody" @selection-change="handleSelectionChange" @sort-change="sortChange" class="thead-light"
        stripe style="width: 100%">
        <!-- 勾选-->
        <el-table-column fixed type="selection" width="55"></el-table-column>
        <!-- 操作 -->
        <el-table-column :width="filterForm.tab === 'normal' ? 220 : 150" fixed="right" label="操作">
          <template slot-scope="scope">
            <el-button-group v-if="filterForm.tab === 'blacklist'">
              <el-button @click="toUpdateBlacklist(scope.row.id, 0)" size="small" type="text">移出黑名单
              </el-button>
            </el-button-group>
            <el-button-group v-if="filterForm.tab === 'deleted'">
              <el-button size="small" type="text">详情</el-button>
              <el-button @click="toPutBack(scope.row.id)" size="small" type="text">还原</el-button>
              <el-button @click="toDelete(scope.row.id, true)" size="small" type="text">删除</el-button>
            </el-button-group>
            <!--              <el-button-group v-if="filterForm.tab === 'checked'">-->
            <!--                -->
            <!--              </el-button-group>-->
            <!--              <el-button-group v-if="filterForm.tab === 'unchecked'">-->
            <!--                -->
            <!--              </el-button-group>-->
            <el-button-group v-else>
              <template v-if="filterForm.tab === 'normal'">
                <el-button @click="toUpdateStatus(scope.row.id, 2)" size="small" type="text">通过</el-button>
                <el-button @click="openCheckDialog(scope.row.id)" size="small" type="text">不通过</el-button>
              </template>
<!--              <el-button v-if="scope.row.check_status === 2" size="small" type="text" @click="toReply(scope.row.id)">回复</el-button>-->
              <el-button @click="toDetail(scope.row.id)" size="small" type="text">详情</el-button>
              <el-button @click="toDelete(scope.row.id)" size="small" type="text">删除</el-button>
            </el-button-group>
          </template>
        </el-table-column>
        <template v-for="(th, index) in thead">
          <el-table-column :key="index" :label="th.label" :min-width="th.min_width || ''"
            :prop="th.prop" :sortable="th.sortable" show-overflow-tooltip>
            <template slot-scope="scope">
              <!-- 标题 -->
              <span class="cursor-pointer" v-if="th.prop === 'article.title'">{{scope.row.article.title | placeholder}}</span>
              <!-- 置顶 -->
              <el-checkbox :checked="scope.row[th.prop] === 1" :disabled="scope.row.ancestor > 0"
                @change="changeStatus(scope.row, scope.$index, th.prop)" v-else-if="th.prop === 'is_top'">
              </el-checkbox>
              <!-- 审核状态 -->
              <template v-else-if="th.prop === 'check_text'">
                <list-status-text :text="scope.row[th.prop]" />
              </template>
              <!-- 运营点赞数 -->
              <ListInputPopover :integer="true" @change="updateCount(scope.row)" class="number-input"
                v-else-if="th.prop === 'praise_count'" v-model="scope.row[th.prop]" />
              <!-- 举报数 -->
              <div v-else-if="th.prop === 'complaints_count'">
                {{ scope.row[th.prop] }}
                <el-button @click="checkReportRecode(scope.row)" type="text" v-if="scope.row[th.prop] > 0">查看
                </el-button>
              </div>
              <!-- 普通 -->
              <span v-else>{{ scope.row[th.prop] | placeholder }}</span>
            </template>
          </el-table-column>
        </template>
      </el-table>
    </div>
    <Pagination :change="changPage" :page="pageData.current_page" :total="pageData.total" />
    <reply-dialog :id="replyDialog.id" @update="getListData" v-model="replyDialog.show"></reply-dialog>

    <!--
                  <el-button type="text" size="small" @click="toUpdateStatus(scope.row.id, 2)">通过</el-button>
                <el-button type="text" size="small" @click="openCheckDialog(scope.row.id)">不通过</el-button>
    -->
    <detail-dialog :id="detailDialog.id" @check="toUpdateStatus($event, 2)" @uncheck="openCheckDialog($event)"
                   @update="afterLikeUpdate"
                   v-model="detailDialog.show"
    />

    <!-- 举报记录弹窗 -->
    <ReportRecodeDialog :requestData="ReportRecodeRequest" v-model="showReportRecodeDialog"/>
    <check-dialog @confirm="handleCheckConfirm" ref="checkDialog" />
  </div>
</template>

<script>
  // 接口
  import {
    checkAudioComment,
    getListData,
    putBack,
    softDelete,
    trueDelete,
    uncheckAudioComment,
  } from "../api/comment-list";
  // 混入
  import init from "../mixins/list-init";
  import filter from "../mixins/list-filter";
  import tabs from "../mixins/list-tabs";
  import list from "../mixins/list-operation";
  import pagination from "../mixins/list-pagination";
  // 组件引入
  import ListFilter from "../components/CommentList/ListFilter";
  import ReplyDialog from "../components/CommentList/ReplyDialog";
  import DetailDialog from "../components/CommentList/DetailDialog";
  import ArticleViewer from "../../../base/components/Preview/ArticleViewer";
  import ListInputPopover from "@/base/components/List/ListInputPopover.vue";
  import ReportRecodeDialog from "@/modules/common/components/ReportRecodeDialog";
  import CheckDialog from "../components/CommentList/CheckDialog";
  import ListStatusText from "@/base/components/List/ListStatusText";

  export default {
  name: "comment-list",
  // 按需混入
  mixins: [init, filter, tabs, list, pagination],
  components: {
    ListStatusText,
    CheckDialog,
    ArticleViewer,
    DetailDialog,
    ReplyDialog,
    ListFilter,
    ListInputPopover,
    ReportRecodeDialog,
  },
  data() {
    return {
      thead: [
        // { label: "ID", prop: "id", min_width: 70 },
        { label: "用户", prop: "nickname", min_width: 110 },
        { label: "评论内容", prop: "content", min_width: 200 },
        {
          label: "评论时间",
          prop: "create_time",
          min_width: 160,
          sortable: false,
        },
        // {
        //   label: "回复数",
        //   prop: "replies_count",
        //   min_width: 100,
        //   sortable: false,
        // },
        // {
        //   label: "运营点赞",
        //   prop: "praise_count",
        //   min_width: 110,
        //   sortable: false,
        // },
        // {
        //   label: "用户点赞",
        //   prop: "likes_count",
        //   min_width: 110,
        //   sortable: false,
        // },
        // { label: "举报数", prop: "complaints_count", min_width: 100 },
        { label: "状态", prop: "check_text", min_width: 90 },
        // { label: "置顶", prop: "is_top", min_width: 80 },
      ],
      tabArr: [
        {
          name: "normal",
          label: "全部",
        },
        // {
        //   name: "checked",
        //   label: "已审核",
        // },
        // {
        //   name: "unchecked",
        //   label: "未审核",
        //   badge: 1
        // },
        // {
        //   name: "rejected",
        //   label: "未通过"
        // },
        // {
        //   name: "blacklist",
        //   label: "黑名单",
        // },
        {
          name: "deleted",
          label: "回收站",
        },
      ],
      // 筛选对象
      filterForm: {
        "page":1,
        "page_size":20,
        "tab":"normal",//全部：normal，回收站：deleted
        "keyword":"",
        "start_time":-1,
        "end_time":-1,
        "order_by":"id",//id,likes_count,create_time
        "is_desc":1,
        "check_status":-1,//0:'待审核',1:'未通过',2:'已通过'
      },
      // 筛选缓存
      filters: {
        normal: null,
        deleted: null,
      },
      pageData: {
        page_size: 15,
        tab: "normal",
      },
      replyDialog: {
        show: false,
        id: -1,
      },
      detailDialog: {
        show: false,
        id: -1,
      },
      previewDialog: {
        show: false,
        data: {},
      },
      showReportRecodeDialog: false, //是否显示举报记录弹窗
      ReportRecodeRequest: {
        page: 1,
        page_size: 15,
        data_type: "comment", // 举报数据类型
        data_table: "portal_comment", // 举报数据表
        data_id: "", // 举报内容id
      },
    };
  },
  created() {
  },
  methods: {
    handleCheckConfirm(e) {
      this.toUpdateStatus(e.id || 0, 1, e.content)
    },
    // 查看举报记录
    checkReportRecode({ id }) {
      this.ReportRecodeRequest.data_id = id;
      this.showReportRecodeDialog = true;
    },
    afterLikeUpdate() {
      this.detailDialog.show = false;
      this.getListData();
    },
    toDetail(e) {
      this.detailDialog.id = e;
      this.detailDialog.show = true;
    },
    toReply(e) {
      this.replyDialog.id = e;
      this.replyDialog.show = true;
    },
    /**
     * 由于每个列表的筛选条件不一，这个方法只能在 view 中定义
     * 用于初始化筛选条件，也作切换tab时还原筛选条件
     * @param {String} name   tab名称，不传时表示初始化
     */
    initFilterForm(name) {
      this.filterForm = Object.assign(
        {},
        {
          start_time: -1, // 开始时间
          end_time: -1, // 结束时间
          keyword: "", // 评论内容
          title: "", // 文章标题
          tab: name || "normal", // 当前筛选tab
          page_size: 15, // 每页多少条数据
        }
      );
    },
    /* 请求API，以do开头 */
    /**
     * 更新评论审核状态
     * @param {Array} id       id数组
     * @param {Number} status   0:待审核，1：不通过， 2：通过
     * @param reason
     */
    doUpdateStatus(id, status, reason) {
      if (typeof id === 'string') {
        if (status === 2) {
          return checkAudioComment({
            id
          })
        } else if (status === 1) {
          return uncheckAudioComment({
            id,
            reason
          })
        }
      } else if (id.length) {
        return Promise.all(id.map(item => new Promise(resolve => {
          if (status === 2) {
            checkAudioComment({
              id: item
            }).finally(() => {
              resolve()
            })
          } else if (status === 1) {
            uncheckAudioComment({
              id: item,
              reason
            }).finally(() => {
              resolve()
            })
          }
        })))
      }
    },
    /**
     * 软删除
     * @param {Array} id      id数组
     * @returns {Promise}     请求结果
     */
    doSoftDelete(id) {
      return softDelete({
        id: id,
      });
    },
    /**
     * 硬删除
     * @param {Array} id
     * @returns {Promise}
     */
    doTrueDelete(id) {
      return trueDelete({
        id: id,
      });
    },
    /**
     * 还原
     * @param {Array} id
     * @returns {Promise}
     */
    doPutBack(id) {
      return putBack({
        id: id,
      });
    },
    doGetListData(params) {
      return getListData(params);
    },
    /**
     * 黑名单
     * @param {Array} id
     * @param {Number} status   0:取消黑名单 ，1：加入黑名单
     */
    doUpdateBlacklist(id, status) {
      return updateBlacklist({
        comment_id: id,
        is_blacklist: status,
      });
    },
    /**
     * 置顶
     * @param id
     */
    doSetTop(id) {
      return setTop(id);
    },
    updateCount(row) {
      savePraise({ comment_id: row.id, praise_count: row.praise_count })
        .then((res) => {})
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
}
</style>
