<template>
  <el-dialog :visible="value" @close="cancel" class="reply-dialog dialog-vertical" title="回复评论" width="500px">
    <el-input :maxlength="250" :rows="6" show-word-limit
              type="textarea" v-focus v-if="value"
              v-loading="loading" v-model="content"
    ></el-input>
    <template #footer>
      <el-button @click="cancel" size="small">取消</el-button>
      <el-button :loading="loading" @click="reply" size="small" type="primary">回复</el-button>
    </template>
  </el-dialog>
</template>

<script>
  // import {reply} from "../../api/comment-list";

  export default {
  name: "ReplyComment",
  props: {
    value: {
      type: Boolean,
    },
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      content: "",
    };
  },
  methods: {
    reply() {
      this.doReply(() => {
        this.$emit("update");
        this.cancel();
      });
    },
    cancel() {
      this.$emit("input", false);
    },
    doReply(callback) {
      this.loading = true;
      // reply({
      //   comment_id: this.id,
      //   content: this.content,
      // })
      //   .then((res) => {
      //     this.loading = false;
      //     callback && callback();
      //   })
      //   .catch((err) => {
      //     this.loading = false;
      //   });
    },
  },
};
</script>

<style scoped>
</style>
